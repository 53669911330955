const rfq = () => {
  return {
    name: 'RFQ',
    active: false,
    layouts: [
      {
        w: 15,
        h: 34,
        x: 0,
        y: 0,
        i: '1613506440579',
        moved: false,
        static: false,
      },
      {
        w: 26,
        h: 16,
        x: 0,
        y: 34,
        i: '1613506492269',
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 14,
        x: 16,
        y: 0,
        i: '1613506512832',
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 20,
        x: 16,
        y: 14,
        i: '1613506527176',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'RFQ',
        id: '1613506440579',
        title: 'RFQ',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1613506440579',
      },
      {
        type: 'CHAT',
        id: '1613506512832',
        title: 'Chat',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 8,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1613506512832',
      },
      {
        type: 'MARKETVIEWEXPANDED',
        id: '1613506527176',
        title: 'Market View',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 8,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          filters: {
            sentByFilter: '',
            sentToFilter: '',
            currencyFilter: '',
            incoTermsFilter: '',
            weekFilter: '',
          },
        },
        i: '1613506527176',
      },
      {
        type: 'BLOTTERS',
        id: '1613506492269',
        title: 'Order and Trade Blotters',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: true,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          tabValue: 2,
          blotterSettings: {
            0: {
              columnState: [
                {
                  colId: 'contract.underlying.colorFan',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.freezeDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqType',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.product',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.location',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.weightClass',
                  hide: false,
                  aggFunc: null,
                  width: 65,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.incoTerms',
                  hide: false,
                  aggFunc: null,
                  width: 82,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'direction',
                  hide: false,
                  aggFunc: null,
                  width: 88,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'volume',
                  hide: false,
                  aggFunc: null,
                  width: 78,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'originalVolume',
                  hide: false,
                  aggFunc: null,
                  width: 92,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.unit',
                  hide: false,
                  aggFunc: null,
                  width: 94,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'price',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.deliveryPoint',
                  hide: true,
                  aggFunc: null,
                  width: 264,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.region',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.packingStation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.currency',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.certification',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.quality',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.state',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.presentation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.instrument',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.week',
                  hide: false,
                  aggFunc: null,
                  width: 112,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.harvestDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.deliveryDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'status',
                  hide: false,
                  aggFunc: null,
                  width: 99,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqId',
                  hide: false,
                  aggFunc: null,
                  width: 86,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'orderGroupId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'visibility',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'recipientList',
                  hide: true,
                  aggFunc: null,
                  width: 217,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeInForce',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'user.fullName',
                  hide: false,
                  aggFunc: null,
                  width: 102,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'organisation.shortName',
                  hide: false,
                  aggFunc: null,
                  width: 69,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeOfEntry',
                  hide: false,
                  aggFunc: null,
                  width: 163,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeOfUpdate',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'textMessage',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'id',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
              ],
              sortState: [
                {
                  colId: 'timeOfUpdate',
                  sort: 'desc',
                },
              ],
              filterState: {},
              floatingFilter: true,
            },
            2: {
              columnState: [
                {
                  colId: 'contract.underlying.colorFan_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqId_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'id_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.product_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeStamp',
                  hide: false,
                  aggFunc: null,
                  width: 160,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'tradeId',
                  hide: false,
                  aggFunc: null,
                  width: 100,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.week_1',
                  hide: false,
                  aggFunc: null,
                  width: 99,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyCompany',
                  hide: false,
                  aggFunc: null,
                  width: 112,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.weightClass_1',
                  hide: false,
                  aggFunc: null,
                  width: 67,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'volume_1',
                  hide: false,
                  aggFunc: null,
                  width: 69,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.unit_1',
                  hide: false,
                  aggFunc: null,
                  width: 72,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'price_1',
                  hide: false,
                  aggFunc: null,
                  width: 83,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.currency_1',
                  hide: false,
                  aggFunc: null,
                  width: 71,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.deliveryDate_1',
                  hide: false,
                  aggFunc: null,
                  width: 128,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.incoTerms_1',
                  hide: false,
                  aggFunc: null,
                  width: 83,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.deliveryPoint_1',
                  hide: false,
                  aggFunc: null,
                  width: 117,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.region_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.packingStation_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'nominal',
                  hide: false,
                  aggFunc: null,
                  width: 91,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.certification_1',
                  hide: false,
                  aggFunc: null,
                  width: 108,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.harvestDate_1',
                  hide: false,
                  aggFunc: null,
                  width: 115,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqType_1',
                  hide: false,
                  aggFunc: null,
                  width: 128,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.quality_1',
                  hide: false,
                  aggFunc: null,
                  width: 128,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.state_1',
                  hide: false,
                  aggFunc: null,
                  width: 115,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.freezeDate_1',
                  hide: false,
                  aggFunc: null,
                  width: 172,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.presentation_1',
                  hide: false,
                  aggFunc: null,
                  width: 87,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'direction_1',
                  hide: false,
                  aggFunc: null,
                  width: 72,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.location_1',
                  hide: false,
                  aggFunc: null,
                  width: 104,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'status_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.instrument_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownUser',
                  hide: false,
                  aggFunc: null,
                  width: 132,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownCompany',
                  hide: true,
                  aggFunc: null,
                  width: 174,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownComments',
                  hide: false,
                  aggFunc: null,
                  width: 162,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyUser',
                  hide: false,
                  aggFunc: null,
                  width: 100,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyComments',
                  hide: false,
                  aggFunc: null,
                  width: 127,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqComments',
                  hide: false,
                  aggFunc: null,
                  width: 96,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'bidId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'offerId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
              ],
              sortState: [],
              filterState: {},
              floatingFilter: true,
            },
          },
        },
        i: '1613506492269',
      },
    ],
    id: '1607529793105',
  };
};

export default rfq;

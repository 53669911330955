const orderManager = () => {
  return {
    name: 'Order Manager',
    active: false,
    layouts: [
      {
        w: 16,
        h: 24,
        x: 0,
        y: 0,
        i: '1613509233792',
        moved: false,
        static: false,
      },
      {
        w: 9,
        h: 24,
        x: 17,
        y: 0,
        i: '1613509255720',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'MARKETVIEWEXPANDED',
        id: '1613509255720',
        title: 'Market View',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 8,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1613509255720',
      },
      {
        type: 'ORDERMANAGER',
        id: '1613509233792',
        title: 'Order Manager',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 24,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          orderListId: '1604341726876',
          calculatorId: 'khdm7lfq',
        },
        i: '1613509233792',
      },
    ],
    id: '1605180946728',
  };
};

export default orderManager;

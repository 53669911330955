import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Popover,
  Typography,
  Icon,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import GroupIcon from '@material-ui/icons/Group';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DashboardIcon from '@material-ui/icons/Notifications';

import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  notifications: {
    width: '440px',
    backgroundColor: theme.palette.background.widget,
  },
  notificationHeader: {
    color: fade(theme.palette.text.primary, 0.7),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main,
  },

  userInfo: {
    padding: theme.spacing(2),
  },
  userInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  key: {
    color: fade(theme.palette.text.secondary, 0.7),
    flex: 1,
  },
  value: {
    flex: 2,
    textAlign: 'right',
  },
  copyright: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
  },
});

const UserSettingsMenu = (props) => {
  const { classes } = props;

  const tradingPartnersListClick = () => {
    props.tradingPartnersListOpen({ open: true });
    props.onClose();
  };

  const notificationSettingsClick = () => {
    props.notificationSettingsOpen({ open: true });
    props.onClose();
  };

  const generalSettingsClick = () => {
    props.generalSettingsOpen({ open: true });
    props.onClose();
  };

  const blotterPresetsClick = () => {
    props.blotterPresetsOpen({ open: true });
    props.onClose();
  };

  return (
    <Popover
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={classes.notifications}>
        <div className={classes.notificationHeader}>
          <Typography variant="h6" color="inherit">
            USER SETTINGS
          </Typography>

          <div>
            <Icon>settings</Icon>
          </div>
        </div>
        <div className={classes.userInfo}>
          <div className={classes.userInfoRow}>
            <div className={classes.key}>User:</div>
            <div className={classes.value}>{props.user.fullName}</div>
          </div>
          <div className={classes.userInfoRow}>
            <div className={classes.key}>Organisation:</div>
            <div className={classes.value}>{props.user.organisation.fullName}</div>
          </div>
          <div className={classes.userInfoRow}>
            <div className={classes.key}>Org. Shortname:</div>
            <div className={classes.value}>{props.user.organisation.shortName}</div>
          </div>
        </div>
        <Divider />
        <List open>
          <ListItem button onClick={notificationSettingsClick}>
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Notification Settings" />
          </ListItem>
          <ListItem button onClick={generalSettingsClick}>
            <ListItemIcon>
              <Icon>format_quote</Icon>
            </ListItemIcon>
            <ListItemText primary="Manage RFQ Presets" />
          </ListItem>
          <ListItem button onClick={blotterPresetsClick}>
            <ListItemIcon>
              <Icon>view_list</Icon>
            </ListItemIcon>
            <ListItemText primary="Manage Blotter Presets" />
          </ListItem>
          <ListItem button onClick={tradingPartnersListClick}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Trading Partners" />
          </ListItem>
          <ListItem button onClick={props.logout}>
            <ListItemIcon>
              <Icon>power_settings_new</Icon>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
        <Divider />
        <Typography variant="subtitle1" color="textSecondary" classes={{ root: classes.copyright }}>
          &copy; 2019-2020 LFEX Limited
        </Typography>
      </div>
    </Popover>
  );
};

UserSettingsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UserSettingsMenu);
